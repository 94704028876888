import './navbar.css'

import React from 'react'

interface NavbarProps {
  handleClose: () => void
}

const Navbar: React.FunctionComponent<NavbarProps> = ({ handleClose }) => {
  return (
    <div className='menu'>
      <span className='rotated' onClick={() => handleClose()}>
        Close
      </span>
    </div>
  )
}

export default Navbar
