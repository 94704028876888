import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { CheckoutContext } from '../context/CheckoutContext'
const NavContent = (props: any) => {
  const navigate = useNavigate()
  const { additionalProducts, setAdditionalProducts, additionalPrice } =
    useContext(CheckoutContext)
  const handleChoose = () => {
    if (props.product.productType === 'main') {
      navigate(`/checkout/${props.product._id}`)
    } else {
      setAdditionalProducts([
        ...additionalProducts,
        {
          id: props.product._id,
          name: props.product.name,
          price: props.product.price,
        },
      ])
    }
  }
  return (
    <div className='nav-content d-flex flex-column'>
      <div style={{ overflow: 'scroll', paddingBottom: '140px' }}>
        <h2 className='text-center mb-5'>{props?.product?.name}</h2>
        <div className='img-wrapper text-center mb-5'>
          <img
            src={`${process.env.REACT_APP_API_BASE_URL}${props?.product?.image}`}
            width='40%'
            alt='product detail'
          />
        </div>
        {props.product.productType === 'main' ? (
          <>
            <div className='d-flex gap-3 justify-content-between mb-4'>
              <div
                className='bg-light'
                style={{
                  padding: '15px',
                  textAlign: 'center',
                  flex: 1,
                  fontWeight: 500,
                }}
              >
                <h4>{props?.product?.hot_water_flow_rate}</h4>
                <p>l/m flow</p>
              </div>
              <div
                className='bg-light'
                style={{
                  padding: '15px',
                  textAlign: 'center',
                  flex: 1,
                  fontWeight: 500,
                }}
              >
                <h4>{props?.product?.central_heating_output}</h4>
                <p>kW Output</p>
              </div>
              <div
                className='bg-light'
                style={{
                  padding: '15px',
                  textAlign: 'center',
                  flex: 1,
                  fontWeight: 500,
                }}
              >
                <h4>{props?.product?.warranty}</h4>
                <p>Year Warranty</p>
              </div>
            </div>
            <h3 className='mb-3'>Description</h3>
            <div
              style={{ fontWeight: 500 }}
              dangerouslySetInnerHTML={{ __html: props?.product?.description }}
            />
            <h3 className='mt-5 mb-3'>The Details</h3>
            <div className='d-flex' style={{ fontWeight: 500 }}>
              <div
                className='p-3 bg-light'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                Height
              </div>
              <div
                className='p-3 bg-light'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                {props?.product?.height}mm
              </div>
            </div>
            <div className='d-flex' style={{ fontWeight: 500 }}>
              <div
                className='p-3'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                Width
              </div>
              <div
                className='p-3'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                {props?.product?.width}mm
              </div>
            </div>
            <div className='d-flex' style={{ fontWeight: 500 }}>
              <div
                className='p-3 bg-light'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                Depth
              </div>
              <div
                className='p-3 bg-light'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                {props?.product?.depth}mm
              </div>
            </div>
            <div className='d-flex' style={{ fontWeight: 500 }}>
              <div
                className='p-3'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                Energy Rating
              </div>
              <div
                className='p-3'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                {props?.product?.efficiency_rating}
              </div>
            </div>
            <div className='d-flex' style={{ fontWeight: 500 }}>
              <div
                className='p-3 bg-light'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                Fuel
              </div>
              <div
                className='p-3 bg-light'
                style={{ flex: 1, border: '1px solid #ccc' }}
              >
                {props?.product?.fuel_type}
              </div>
            </div>
          </>
        ) : (
          <div
            style={{ fontWeight: 500, textAlign: 'center' }}
            dangerouslySetInnerHTML={{ __html: props?.product?.description }}
          />
        )}
      </div>
      <div className='sidebar-footer d-flex align-items-center justify-content-between'>
        <div className='price-wrapper'>
          <p className='fully-installed'>Fully installed</p>
          <h3>
            &pound; {''}
            {props.product.productType === 'main'
              ? (additionalPrice + props.product.price).toFixed(2)
              : props.product.price.toFixed(2)}
          </h3>
        </div>
        <Button className='choose' onClick={handleChoose}>
          Choose
        </Button>
      </div>
    </div>
  )
}

export default NavContent
