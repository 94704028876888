import './boiler.css'

import React, { FunctionComponent } from 'react'

interface SelectBoilerProps {
  step: number
  totalStep: number
}

const Boiler: FunctionComponent<SelectBoilerProps> = ({ step, totalStep }) => {
  const steps = Array.from({ length: totalStep }, (_, index) => index + 1)
  return (
    <div className='boiler'>
      <div className='boiler-left'>
        <h2>
          {step === 1
            ? 'Select your Boiler'
            : step === 2
            ? 'Additional Options'
            : step === 3
            ? 'Select your installation Date'
            : step === 4
            ? 'Your Details'
            : step === 5
            ? 'Payment'
            : ''}
        </h2>
      </div>
      <div className='boiler-right'>
        <div className='step-wrapper'>
          {steps.map((stp) => {
            return (
              <div key={stp} className={stp <= step ? 'active' : ''}>
                <span>{stp}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Boiler
