import './singleProduct.css'

import React, { FunctionComponent, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { CheckoutContext } from '../context/CheckoutContext'

type SingleProductProps = {
  handleOpen: () => void
  setSelectedProduct: (data: any) => void
  product: any
}

const SingleProduct: FunctionComponent<SingleProductProps> = ({
  handleOpen,
  setSelectedProduct,
  product,
}) => {
  const navigate = useNavigate()
  const { additionalProducts, setAdditionalProducts, additionalPrice } =
    useContext(CheckoutContext)
  const handleChoose = () => {
    if (product.productType === 'main') {
      navigate(`/checkout/${product._id}`)
    } else {
      setAdditionalProducts([
        ...additionalProducts,
        { id: product._id, name: product.name, price: product.price },
      ])
    }
  }
  return (
    <div className='productWrapper'>
      <div className='product-heading'>
        <h2 className='text-center mb-4'>{product.name}</h2>
        <div className='img-wrapper mb-3'>
          <img
            src={`${process.env.REACT_APP_API_BASE_URL}${product.image}`}
            alt=''
            className='img-fluid'
          />
        </div>
        <div className='text-center mb-4'>
          <h5>Fully Installed</h5>
          <span className='price'>
            &pound;{' '}
            {product.productType === 'main' && additionalPrice
              ? (additionalPrice + product.price).toFixed(2)
              : product.price.toFixed(2)}
          </span>
        </div>
        <div className='d-flex flex-column mt-auto'>
          <button
            className='more-info mb-2'
            onClick={() => {
              handleOpen()
              setSelectedProduct(product)
            }}
          >
            More info &gt;
          </button>
          <Button className='choose' onClick={handleChoose}>
            {product.productType === 'main' ? 'Select This Boiler' : 'Choose'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SingleProduct
