import React, { useEffect, useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'

import back from '../assets/back.png'
import combi from '../assets/combi.png'
import gas from '../assets/gas.png'
import lpg from '../assets/lpg.png'
import regular from '../assets/regular.png'
import system from '../assets/system.png'

interface BoilerRecommendationProps {
  answer: string
  onClick: () => void
  boxActive: boolean
  question: string
  questionNo: number
}

const OptionBox: React.FC<BoilerRecommendationProps> = ({
  answer,
  onClick,
  question,
  questionNo,
  boxActive = false,
}) => {
  const [active, setActive] = useState<boolean>(false)
  const [info, setInfo] = useState<string>('')

  const handleInfoClick = (event: any) => {
    event.stopPropagation()
    setActive(!active)
  }

  useEffect(() => {
    if (answer === 'Gas') {
      setInfo(`If your home has a gas meter, your boiler will likely be powered by mains gas
      `)
    }
    if (answer === 'LPG') {
      setInfo(`If your fuel is stored in a tank outside your home, your boiler uses LPG
      `)
    }
    if (answer === 'Combi') {
      setInfo(`A combi boiler heats water on demand and does not have separate hot water or cold-water storage tanks.
      `)
    }
    if (answer === 'Regular') {
      setInfo(
        `A regular boiler, also known as a conventional boiler, requires both a separate hot water cylinder and a cold-water storage tank (usually in the loft).`
      )
    }
    if (answer === 'System') {
      setInfo(
        `A system boiler has an integrated hot water tank, but does not need a separate cold-water tank (usually in the loft).`
      )
    }
    if (answer === 'Back Boiler') {
      setInfo(`Found in older homes, usually behind a fireplace`)
    }
  }, [answer])

  return (
    <div className={`optionBox ${boxActive ? 'active' : ''}`} onClick={onClick}>
      {info && (
        <span onClick={handleInfoClick} className='infoIcon'>
          <BsInfoCircle style={{ fontSize: '20px' }} />
        </span>
      )}
      {answer === 'Gas' && <img src={gas} width='100px' alt='icon' />}
      {answer === 'LPG' && <img src={lpg} width='100px' alt='icon' />}
      {answer === 'Combi' && <img src={combi} width='100px' alt='icon' />}
      {answer === 'Regular' && <img src={regular} width='100px' alt='icon' />}
      {answer === 'System' && <img src={system} width='100px' alt='icon' />}
      {answer === 'Back Boiler' && <img src={back} width='100px' alt='icon' />}
      {answer === 'Yes' && (
        <div
          className='yesIconWrapper'
          style={{
            height: '140px',
            width: '140px',
            borderRadius: '50%',
            border: '2px solid #111',
            fontSize: '100px',
            color: '#FDDA00',
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MdCheck />
        </div>
      )}
      {answer === 'No' && (
        <div
          className='yesIconWrapper'
          style={{
            height: '140px',
            width: '140px',
            borderRadius: '50%',
            border: '2px solid #111',
            fontSize: '100px',
            color: '#FDDA00',
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MdClose />
        </div>
      )}
      {answer.includes('years') && (
        <div
          className='yesIconWrapper'
          style={{
            height: '140px',
            width: '140px',
            borderRadius: '50%',
            fontSize: '35px',
            fontWeight: 700,
            color: '#fff',
            backgroundColor: '#FDDA00',
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span>{answer.split(' ')[0]}</span>
        </div>
      )}
      {(answer === '1' ||
        answer === '2' ||
        answer === '2+' ||
        answer === '3' ||
        answer === '3+' ||
        answer === '4' ||
        answer === '1-5' ||
        answer === '5-10' ||
        answer === '10-15' ||
        answer === '15+' ||
        answer === '5+') && (
        <div
          className='yesIconWrapper'
          style={{
            height: '140px',
            width: '140px',
            borderRadius: '50%',
            fontSize: '35px',
            fontWeight: 700,
            color: '#fff',
            backgroundColor: '#FDDA00',
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span>{answer}</span>
        </div>
      )}
      {answer === 'I don’t know' && (
        <div
          className='yesIconWrapper'
          style={{
            height: '140px',
            width: '140px',
            borderRadius: '50%',
            fontSize: '35px',
            fontWeight: 700,
            color: '#fff',
            backgroundColor: '#FDDA00',
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span>?</span>
        </div>
      )}
      <p>
        {answer}
        {questionNo === 7 &&
          question !== 'Where is your current boiler?' &&
          (answer === 'Utility Room' ||
            answer === 'Kitchen' ||
            answer === 'Garage' ||
            answer === 'Bathroom' ||
            answer === 'Bedroom' ||
            answer === 'Loft') && (
            <>
              <br />
              <span>&pound; 600</span>
            </>
          )}
        {questionNo === 7 && answer === 'Same room' && (
          <>
            <br />
            <span>&pound; 600</span>
          </>
        )}
        {questionNo === 7 && answer === 'Same floor' && (
          <>
            <br />
            <span>&pound; 800</span>
          </>
        )}
        {questionNo === 7 && answer === 'Another floor' && (
          <>
            <br />
            <span>&pound; 900</span>
          </>
        )}
      </p>
      <div className={`hoverDiv ${active ? 'active' : ''}`}>
        <p>{answer}</p>
        <span className='div-info'>{info}</span>
      </div>
    </div>
  )
}

export default OptionBox
