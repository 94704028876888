import React, { FunctionComponent } from 'react'

interface IFilterProps {
  handleSortByPriceAscending: () => void
  handleSortByPriceDescending: () => void
  handleSortByTitleAscending: () => void
  handleSortByTitleDescending: () => void
  handleFilterByFuel: (fuel: string) => void
}

const FilterSortDropdown: FunctionComponent<IFilterProps> = ({
  handleSortByPriceAscending,
  handleSortByPriceDescending,
  handleSortByTitleAscending,
  handleSortByTitleDescending,
  handleFilterByFuel,
}) => {
  const handleFilterSelect = (event: any) => {
    const selectedValue = event.target.value
    // Handle the selected filter option here
    console.log('Filter Selected:', selectedValue)
    handleFilterByFuel(selectedValue)
  }

  const handleSortSelect = (event: any) => {
    const selectedValue = event.target.value
    // Handle the selected sort option here
    console.log('Sort Selected:', selectedValue)
    if (selectedValue === 'priceAsc') {
      handleSortByPriceAscending()
    } else if (selectedValue === 'priceDesc') {
      handleSortByPriceDescending()
    } else if (selectedValue === 'titleAsc') {
      handleSortByTitleAscending()
    } else if (selectedValue === 'titleDesc') {
      handleSortByTitleDescending()
    } else {
      return
    }
  }

  return (
    <>
      <select
        className='custom-select ms-2'
        onChange={handleFilterSelect}
        style={{
          borderRadius: '20px',
          padding: '5px 10px 8px',
          backgroundColor: '#FDDA30',
          border: 'none',
          fontWeight: 500,
          width: '8em',
        }}
      >
        <option value='none'>Brand</option>
        <option value='Worcester'>Worcester</option>
        <option value='Vaillant'>Vaillant</option>
        <option value='Glow worm'>Glow worm</option>
        {/* Add more filter options as needed */}
      </select>
      <select
        className='custom-select ms-2'
        onChange={handleSortSelect}
        style={{
          borderRadius: '20px',
          padding: '5px 25px 8px',
          backgroundColor: '#FDDA30',
          border: 'none',
          fontWeight: 500,
          width: '12em',
        }}
      >
        <option value=''>Sort</option>
        <option value='priceAsc'>Price (low to high)</option>
        <option value='priceDesc'>Price (high to low)</option>
        <option value='titleAsc'>Title Ascending</option>
        <option value='titleDesc'>Title Descending</option>
        {/* Add more sorting options as needed */}
      </select>
    </>
  )
}

export default FilterSortDropdown
