import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import Footer from '../components/Footer'
import PageNav from '../components/pageNav'
import Sidebar from '../components/Sidebar'

export default function CheckoutSuccess() {
  const location = useLocation()
  const [orderId, setOrderId] = useState<string | null>('')
  const [installationDate, setInstallationDate] = useState<string>('')
  const [address, setAddress] = useState<string | null>('')

  const [open, setOpen] = useState<boolean>(false)
  const [needHelp, setNeedHelp] = useState<boolean>(false)

  const handleOpenClose = () => {
    setOpen(!open)
    if (needHelp) {
      setNeedHelp(!needHelp)
    }
  }

  const handleOpenNeedHelp = () => {
    handleOpenClose()
    setNeedHelp(true)
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const orderIdParam = queryParams.get('orderId')
    const installationDateParam = queryParams.get('installationDate')
    const addressParam = queryParams.get('address')

    setOrderId(orderIdParam)
    if (installationDateParam) {
      const splitDate = installationDateParam.split(' ')
      const installDate =
        dayjs(`${splitDate[1]}/${splitDate[2]}/${splitDate[3]}`).format(
          'MM/DD/YYYY'
        ) === 'Invalid Date'
          ? dayjs(`${splitDate[1]}-${splitDate[2]}-${splitDate[3]}`).format(
              'MM/DD/YYYY'
            )
          : dayjs(`${splitDate[1]}/${splitDate[2]}/${splitDate[3]}`).format(
              'MM/DD/YYYY'
            )
      setInstallationDate(installDate)
    }
    setAddress(addressParam)
  }, [location.search])
  return (
    <>
      <PageNav handleOpenNeedHelp={handleOpenNeedHelp} />
      <Container
        className='text-center d-flex flex-column justify-content-center'
        style={{
          height: '60vh',
          padding: '18rem 0',
          maxWidth: '100%',
          width: '655px',
        }}
      >
        <h1>Thank you for your order!</h1>
        <p style={{ marginTop: '40px', fontWeight: 500, fontSize: '20px' }}>
          Your order number is: {orderId}{' '}
        </p>
        <p style={{ marginTop: '40px' }}>
          <>
            {' '}
            We will install your boiler at {address} on {installationDate}
          </>
        </p>
        <p style={{ marginTop: '40px', marginBottom: '40px' }}>
          Receipt of your purchase and details for your install have been sent
          to your email. If you have any questions in the meantime please don't
          hesitate to contact us.
        </p>
        <Button color='primary' href='/'>
          Return To Main Site
        </Button>
      </Container>
      <Sidebar open={open} needHelp={needHelp} handleClose={handleOpenClose} />
      <Footer />
    </>
  )
}
