import { useState } from 'react'

import Footer from '../components/Footer'
import PageNav from '../components/pageNav'
import ProductListing from '../components/ProductListing'
import Sidebar from '../components/Sidebar'

function ProductPage() {
  const [open, setOpen] = useState<boolean>(false)
  const [needHelp, setNeedHelp] = useState<boolean>(false)

  const handleOpenClose = () => {
    setOpen(!open)
    if (needHelp) {
      setNeedHelp(!needHelp)
    }
  }

  const handleOpenNeedHelp = () => {
    handleOpenClose()
    setNeedHelp(true)
  }
  return (
    <>
      <PageNav handleOpenNeedHelp={handleOpenNeedHelp} />
      <ProductListing />
      <Sidebar open={open} needHelp={needHelp} handleClose={handleOpenClose} />
      <Footer />
    </>
  )
}

export default ProductPage
