import './result.css'

import React from 'react'
import { FunctionComponent } from 'react'
import { Button } from 'react-bootstrap'

import FilterSortDropdown from './FilterSortDropdown'

interface Selectresultprop {
  result: number
  handleSortByPriceAscending: () => void
  handleSortByPriceDescending: () => void
  handleSortByTitleAscending: () => void
  handleSortByTitleDescending: () => void
  handleFilterByFuel: (fuel: string) => void
}

const Result: FunctionComponent<Selectresultprop> = ({
  result,
  handleSortByPriceAscending,
  handleSortByPriceDescending,
  handleSortByTitleAscending,
  handleSortByTitleDescending,
  handleFilterByFuel,
}) => {
  return (
    <div className='result'>
      <div className='result_left'>
        <h2>We offer</h2>
        <h4>{result} boilers perfect for your home</h4>
      </div>

      <div className='result_right'>
        <Button style={{ background: '#eff1f9' }} href='/'>
          Restart Your Quote
          <svg
            fill='black'
            height='24'
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
            style={{ transform: 'rotate(-90deg)' }} // Rotate the entire SVG to flip it
          >
            <path d='M7 10l5 5 5-5z' />
            <path d='M0 0h24v24H0z' fill='none' />
          </svg>
        </Button>

        {/* <button className='filter'>Filter </button>
        <button className='sort'>Sort</button> */}
        <FilterSortDropdown
          handleSortByPriceAscending={handleSortByPriceAscending}
          handleSortByPriceDescending={handleSortByPriceDescending}
          handleSortByTitleAscending={handleSortByTitleAscending}
          handleSortByTitleDescending={handleSortByTitleDescending}
          handleFilterByFuel={handleFilterByFuel}
        />
      </div>
    </div>
  )
}

export default Result
