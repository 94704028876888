import { useState } from 'react'

import Footer from '../components/Footer'
import IssueComponent from '../components/issueOccurance'
import PageNav from '../components/pageNav'
import Sidebar from '../components/Sidebar'

function PostCodeNotCoveredPage() {
  const [open, setOpen] = useState<boolean>(false)
  const [needHelp, setNeedHelp] = useState<boolean>(false)

  const handleOpenClose = () => {
    setOpen(!open)
    if (needHelp) {
      setNeedHelp(!needHelp)
    }
  }

  const handleOpenNeedHelp = () => {
    handleOpenClose()
    setNeedHelp(true)
  }
  return (
    <>
      <PageNav handleOpenNeedHelp={handleOpenNeedHelp} />
      <IssueComponent
        message={"Unfortunately we don't cover your location at this time"}
      />
      <Sidebar open={open} needHelp={needHelp} handleClose={handleOpenClose} />
      <Footer />
    </>
  )
}

export default PostCodeNotCoveredPage
