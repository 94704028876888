import 'react-circular-progressbar/dist/styles.css'

import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Spinner from 'react-bootstrap/Spinner'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useNavigate } from 'react-router-dom'

import Footer from '../components/Footer'
import OptionBox from '../components/OptionBox'
import PageNav from '../components/pageNav'
import Sidebar from '../components/Sidebar'
import { CheckoutContext } from '../context/CheckoutContext'
import { useGetQuestions } from '../queries/questions'

function Home() {
  const [questionNo, setQuestionNo] = useState<number>(2)
  const [answer, setAnswer] = useState<string>('')
  const [question, setQuestion] = useState<string>('')
  const { quiz, setQuiz, prevQues, setPrevQues } = useContext(CheckoutContext)
  const [postValidationError, setPostValidationError] = useState('')

  const navigate = useNavigate()

  const [tempQuestionNo, setTempQuestionNo] = useState<number>(2)
  const [tempAnswer, setTempAnswer] = useState<string>('')

  const [value, setValue] = useState('')

  const [open, setOpen] = useState<boolean>(false)
  const [needHelp, setNeedHelp] = useState<boolean>(false)

  const handleOpenClose = () => {
    setOpen(!open)
    if (needHelp) {
      setNeedHelp(!needHelp)
    }
  }

  const handleOpenNeedHelp = () => {
    handleOpenClose()
    setNeedHelp(true)
  }

  const handleBlur = (e: any) => {
    if (questionNo === 1) {
      const inputValue = e.target.value
      if (inputValue.length < 2 || inputValue.length > 4) {
        setPostValidationError('Input must be between 2 and 4 characters.')
      } else {
        setPostValidationError('')
      }
    }
  }

  const handlePrevQuestion = () => {
    if (!prevQues.includes(questionData?.data.message.question_no || 2)) {
      setPrevQues([...prevQues, questionData?.data.message.question_no || 2])
    }
  }

  const {
    data: questionData,
    isLoading,
    error,
    isError,
  } = useGetQuestions({
    question_no: questionNo,
    ans_option: answer,
    quiz: quiz,
  })

  useEffect(() => {
    if (
      questionData?.data.message &&
      typeof questionData?.data.message === 'string'
    ) {
      navigate('/quiz-summary')
    }
  }, [questionData])

  const handleNext = () => {
    if (postValidationError.length) {
      return
    }
    if (
      questionData &&
      questionData.data.message &&
      typeof questionData?.data.message !== 'string' &&
      questionData?.data.message?.answer_options.length === 0
    ) {
      setQuestionNo(questionData?.data.message.question_no || 2)
      setQuestion(questionData?.data.message.question || '')
      setAnswer(value.trim().toUpperCase())
      setValue('')
    } else {
      if (!tempAnswer.length) {
        alert('Empty answer...')
        return
      }
      handlePrevQuestion()
      setQuestionNo(tempQuestionNo)
      setAnswer(tempAnswer)
    }
  }
  // useEffect(() => {
  //   handleNext()
  // }, [questionData])
  useEffect(() => {
    if (!isError) {
      if (value) {
        setAnswer(value.toUpperCase())
      }
    }
    if (isError && questionNo === 1) {
      navigate('/postcode-unavailable')
    }
  }, [isError])
  const handleBack = () => {
    console.log(prevQues)
    const arrInd = prevQues.length > 2 ? prevQues.length - 2 : 1
    console.log(arrInd, prevQues[arrInd])
    setQuestionNo(prevQues[arrInd])
    if (questionNo === 1) {
      setAnswer(value || 'CW')
    } else {
      console.log(quiz[arrInd])
      setAnswer(quiz[arrInd].answer || '')
    }
    const tempPrev = [...prevQues]
    if (tempPrev.length > 2) {
      tempPrev.pop()
    }
    setPrevQues(tempPrev)
    const tempQuiz = [...quiz]
    tempQuiz.pop()
    setQuiz(tempQuiz)
  }
  useEffect(() => {
    if (question !== '') {
      if (
        (question === 'Where do you want your new boiler to be?' &&
          answer === 'Other') ||
        (question === 'Where would you like your new boiler to be?' &&
          answer === 'Other')
      ) {
        navigate('/help')
      }
      const questionIndex = quiz.findIndex(
        (questionObj) => questionObj.question === question
      )
      if (questionIndex !== -1) {
        const updatedQuiz = [...quiz]
        updatedQuiz[questionIndex].answer = answer
      } else {
        setQuiz([...quiz, { question: question, answer: answer }])
      }
    }
  }, [answer, question])
  if (
    questionData?.data.message &&
    typeof questionData?.data.message === 'string'
  ) {
    return (
      <>
        <PageNav handleOpenNeedHelp={handleOpenNeedHelp} />
        <Spinner animation='border' variant='warning' />
        <Footer />
      </>
    )
  } else {
    return (
      <div className='App'>
        <PageNav handleOpenNeedHelp={handleOpenNeedHelp} />
        <div>
          {isLoading ? (
            <div className='py-5 bg-dark'>
              <Spinner animation='border' variant='warning' />
            </div>
          ) : (
            <>
              <h3 className='py-5 px-5 bg-dark text-white mb-0'>
                <Container className='container--narrow'>
                  {questionData?.data.message.question || ''}
                </Container>
              </h3>
              <div className='progressWrapper'>
                <ProgressBar now={(questionNo / 21) * 100} />
              </div>
              <Container className='container--narrow'>
                <div className='d-flex py-5 px-md-5 flex-wrap justify-content-center gap-4'>
                  {questionData?.data.message &&
                  questionData?.data.message?.answer_options.length ? (
                    questionData?.data.message.answer_options.map(
                      (ansOption, index) => {
                        return (
                          <OptionBox
                            key={index}
                            boxActive={ansOption === tempAnswer}
                            questionNo={questionNo}
                            question={questionData?.data.message.question}
                            answer={ansOption}
                            onClick={() => {
                              setTempAnswer(ansOption)
                              setTempQuestionNo(
                                questionData.data.message.question_no
                              )
                              setAnswer(ansOption)
                              setQuestionNo(
                                questionData.data.message.question_no
                              )
                              handlePrevQuestion()
                              setQuestion(questionData.data.message.question)
                            }}
                          />
                        )
                      }
                    )
                  ) : (
                    <Form className='px-3 px-md-5 py-5'>
                      {questionNo === 1 && (
                        <p
                          style={{
                            marginBottom: '30px',
                            fontSize: '20px',
                            fontWeight: 500,
                          }}
                        >
                          Just the first half, so we can check we cover your
                          area
                        </p>
                      )}
                      <Form.Group controlId='myInputField'>
                        <Form.Control
                          type='text'
                          placeholder={questionData?.data.message.question}
                          value={value}
                          minLength={2}
                          maxLength={questionNo > 1 ? 100 : 4}
                          onChange={(e) => setValue(e.target.value)}
                          onBlur={handleBlur}
                          className='postcode-input'
                          style={{
                            borderRadius: '4px',
                            borderRight: 'none',
                            marginBottom: '10px',
                            textTransform: 'uppercase',
                          }}
                        />
                        {isError && (
                          <p className='text-danger'>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            {error.response?.data?.message || ''}
                          </p>
                        )}
                        {postValidationError && (
                          <p className='text-danger'>
                            {postValidationError || ''}
                          </p>
                        )}
                        <Button variant='primary' onClick={handleNext}>
                          Next
                        </Button>
                      </Form.Group>
                      {questionNo === 1 && (
                        <p
                          style={{
                            marginTop: '30px',
                            fontSize: '20px',
                            fontWeight: 500,
                          }}
                        >
                          <Button variant='text' onClick={() => setOpen(true)}>
                            Which areas do we cover &gt;
                          </Button>
                        </p>
                      )}
                    </Form>
                  )}
                </div>
                {questionNo === 16 && (
                  <p
                    style={{
                      marginTop: '30px',
                      fontSize: '20px',
                      fontWeight: 500,
                    }}
                  >
                    <Button variant='text' onClick={() => setOpen(true)}>
                      What is a TRV &gt;
                    </Button>
                  </p>
                )}
              </Container>
            </>
          )}
        </div>
        <Sidebar
          open={open}
          needHelp={needHelp}
          isPostcodeInfo={questionNo === 1}
          isTRVInfo={questionNo === 16}
          handleClose={handleOpenClose}
        />
        <div className='footer-btn-wrapper'>
          <Container className='d-flex justify-content-between'>
            <div className='progressAndTextWrapper d-flex gap-2 align-items-center'>
              <div
                className='progressWrapper'
                style={{ width: '40px', height: '40px' }}
              >
                <CircularProgressbar
                  value={(questionNo / 21) * 100}
                  styles={buildStyles({
                    pathColor: '#FDDA30',
                    trailColor: '#EFF1F9',
                  })}
                />
              </div>
              <div className='progressText'>
                {Math.floor((questionNo / 21) * 100)}% complete
              </div>
            </div>
            <div className='btns-wrapper d-flex gap-2'>
              {(questionData?.data.message.question_no || 2) > 1 && (
                <Button variant='outline-dark' onClick={handleBack}>
                  Back
                </Button>
              )}
              <Button onClick={handleNext}>Next</Button>
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Home
