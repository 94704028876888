/* eslint-disable @typescript-eslint/ban-types */
import { getApiClient } from '../modules/axios'
import { useGetMutation } from '../modules/mutation'

interface IOrderProduct {
  product: String
}

interface OrderBody {
  Products: IOrderProduct[]
  PaymentType: string
  InstallationDate: string
  FirstName: string
  LastName: string
  Email: string
  ContactNumber: number
  StreetName: string
  City: string
  PostCode: string
  Quiz: string
}

const createOrder = (data: OrderBody) => {
  return getApiClient({
    'Content-Type': 'application/json',
  }).post(`/checkout/inline`, data)
}

export const useCreateOrder = ({
  onSuccess,
  onError,
}: {
  onSuccess: Function
  onError: Function
}) => {
  return useGetMutation(createOrder, onSuccess, onError)
}
