import './App.css'

import { QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// import ProductListing from './components/ProductListing'
import { getQueryClient } from './modules/queryClient'
import Checkout from './pages/checkout'
import HelpPage from './pages/helpPage'
import Home from './pages/home'
import PostCodeNotCoveredPage from './pages/postCodeNotCovered'
import ProductPage from './pages/product'
import { QuizSummaryPage } from './pages/QuizSummaryPage'
import StopPage from './pages/stopPage'
import CheckoutSuccess from './pages/success'

function App() {
  const queryClient = getQueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/quiz-summary' element={<QuizSummaryPage />} />
          <Route path='/products' element={<ProductPage />} />
          <Route path='/checkout/:productId' element={<Checkout />} />
          <Route path='/checkout/success' element={<CheckoutSuccess />} />
          <Route
            path='/postcode-unavailable'
            element={<PostCodeNotCoveredPage />}
          />
          <Route path='/help' element={<HelpPage />} />
          <Route path='/stop' element={<StopPage />} />
        </Routes>
      </BrowserRouter>
      {/* <ProductListing /> */}
    </QueryClientProvider>
  )
}

export default App
