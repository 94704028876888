/* eslint-disable @typescript-eslint/ban-types */
import { getApiClient } from '../modules/axios'
import { useGetMutation } from '../modules/mutation'

const getBlockedDates = (currentMonth: string) => {
  return getApiClient().get(`/calendar/list-full-month/${currentMonth}`)
}

export const useGetBlockedDates = ({
  onSuccess,
  onError,
}: {
  onSuccess: Function
  onError: Function
}) => {
  return useGetMutation(getBlockedDates, onSuccess, onError)
}
