import 'react-calendar/dist/Calendar.css'

import { zodResolver } from '@hookform/resolvers/zod'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { AxiosError } from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import Calendar from 'react-calendar'
import { useForm } from 'react-hook-form'
import { BsInfoCircle } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import Boiler from '../components/Boiler'
import CheckoutForm from '../components/CheckoutForm'
import Footer from '../components/Footer'
import PageNav from '../components/pageNav'
import ProductListing from '../components/ProductListing'
import Sidebar from '../components/Sidebar'
import { CheckoutContext } from '../context/CheckoutContext'
import { useGetBlockedDates } from '../queries/calendar'
import { useCheckCoupon } from '../queries/coupon'
import { useCreateOrder } from '../queries/order'
import { useGetProduct } from '../queries/products'

const schema = z.object({
  FirstName: z.string().nonempty('First name is required'),
  LastName: z.string().nonempty('Last name is required'),
  Email: z
    .string()
    .email('Invalid email address')
    .nonempty('Email is required'),
  ContactNumber: z.string().nonempty('Contact number is required'),
  HouseNumber: z.string().nonempty('House Number is required'),
  StreetName: z.string().nonempty('Street is required'),
  Town: z.string().nonempty('Town is required'),
  County: z.string().nonempty('County is required'),
  PostCode: z.string().nonempty('Postal code is required'),
  InstallationDate: z.string().nonempty('Installation date is required'),
  // 'Products[0].Product': z.string().nonempty('Product id is required'),
  // PaymentType: z.string().nonempty('Payment Type is required'),
})

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK || '')

function CheckoutPage() {
  const {
    checkoutState,
    setCheckoutState,
    additionalProducts,
    setAdditionalProducts,
    installationDate,
    setInstallationDate,
    quiz,
    additionalPrice,
  } = useContext(CheckoutContext)
  const navigate = useNavigate()
  const { productId } = useParams()
  const [clientSecret, setClientSecret] = useState('')
  const [orderId, setOrderId] = useState('')
  const [blockedDates, setBlockedDates] = useState<number[]>([0])
  const [groupedProducts, setGroupedProducts] = useState([])
  const [couponName, setCouponName] = useState('')
  const [open, setOpen] = useState<boolean>(false)
  const [needHelp, setNeedHelp] = useState<boolean>(false)

  const handleOpenClose = () => {
    setOpen(!open)
    if (needHelp) {
      setNeedHelp(!needHelp)
    }
  }

  const handleOpenNeedHelp = () => {
    handleOpenClose()
    setNeedHelp(true)
  }
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  }
  const { data: product, isLoading: productLoading } = useGetProduct(
    productId || ''
  )
  const today = new Date()
  let tomorrow
  if (today.getHours() >= 14) {
    tomorrow = new Date(today.setDate(today.getDate() + 2))
  } else {
    tomorrow = new Date(today.setDate(today.getDate() + 1))
  }

  const { mutate: createOrder, isLoading } = useCreateOrder({
    onSuccess: (data: any) => {
      setClientSecret(data?.data?.clientSecret)
      setOrderId(data.data.orderId)
      console.log('clientSecret', data)
      setCheckoutState(5)
      //window.location.href = data.data.url
    },
    onError: (err: AxiosError) => {
      console.log('error', err)
    },
  })

  const { mutate: getBlockedDates, isLoading: blockDateLoading } =
    useGetBlockedDates({
      onSuccess: (data: any) => {
        if (data.data.calendar.length) {
          const numDates = data.data.calendar.map((value: string) =>
            Number(value)
          )
          console.log(numDates)
          setBlockedDates(numDates)
        } else {
          setBlockedDates([0])
        }
      },
      onError: (err: AxiosError) => {
        console.log(err)
      },
    })

  const {
    mutate: checkCoupon,
    isLoading: couponLoading,
    data: couponData,
    error: couponError,
  } = useCheckCoupon({
    onSuccess: (data: any) => {
      console.log('couopon data', data)
      //window.location.href = data.data.url
    },
    onError: (err: AxiosError) => {
      console.log('error', err)
    },
  })

  const handleCouponChange = (event: any) => {
    setCouponName(event.target.value)
  }

  const handleCheckCoupon = () => {
    checkCoupon(couponName)
  }

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  const onSubmit = (data: any) => {
    // Handle form submission
    const additionalProductData = additionalProducts.map((item) => ({
      Product: item.id,
    }))
    const checkoutData = {
      ...data,
      Coupon: couponData
        ? {
            _id: couponData?.data?.data._id,
          }
        : null,
      PaymentType: 'card',
      Quiz: JSON.stringify(quiz),
      Products: [{ Product: productId }, ...additionalProductData],
    }
    console.log(checkoutData.Products)
    createOrder(checkoutData)
  }

  const triggerFormSubmission = () => {
    handleSubmit(onSubmit)()
    console.log(errors)
    if (!isDirty && isValid) setCheckoutState(5)
  }

  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 90
  )

  const handleMonthChange = ({ activeStartDate }: { activeStartDate: any }) => {
    const month =
      activeStartDate.getMonth() + 1 > 9
        ? activeStartDate.getMonth() + 1
        : '0' + (activeStartDate.getMonth() + 1)

    const dateString = `${month}-${activeStartDate.getFullYear()}`
    getBlockedDates(dateString)
  }

  const handleInstallationDate = (date: Date) => {
    setInstallationDate(date)
  }

  useEffect(() => {
    if (!quiz.length) {
      navigate('/')
    }
  }, [quiz])

  useEffect(() => {
    if (installationDate) {
      setValue('InstallationDate', installationDate.toDateString())
      trigger('InstallationDate')
    }
  }, [installationDate])

  const handleNextStep = () => {
    if (checkoutState === 2) {
      setCheckoutState(3)
    } else if (checkoutState === 3) {
      setCheckoutState(4)
    } else if (checkoutState === 4) {
      triggerFormSubmission()
      if (!isDirty && isValid) setCheckoutState(5)
    }
  }
  const handleBack = () => {
    if (checkoutState === 2) {
      navigate('/products')
    } else if (checkoutState === 3) {
      setCheckoutState(2)
    } else if (checkoutState === 4) {
      setCheckoutState(3)
    } else if (checkoutState === 5) {
      setCheckoutState(4)
    }
  }

  useEffect(() => {
    if (today) {
      const month =
        today.getMonth() + 1 > 9
          ? today.getMonth() + 1
          : '0' + (today.getMonth() + 1)

      const dateString = `${month}-${today.getFullYear()}`
      getBlockedDates(dateString)
    }
  }, [])

  useEffect(() => {
    const grouped = additionalProducts.reduce((acc: any, prdt: any) => {
      const existingProduct = acc.find((item: any) => item.id === prdt.id)
      if (existingProduct) {
        existingProduct.quantity++
      } else {
        acc.push({ ...prdt, quantity: 1 })
      }
      return acc
    }, [])

    // Update the state with the grouped products
    setGroupedProducts(grouped)
  }, [additionalProducts])

  const formatWeekday = (locale: any, date: any) => {
    return date.toLocaleDateString(locale, { weekday: 'short' }).charAt(0)
  }

  // Handle quantity changes on additionalProducts
  const handleQuantityChange = (id: string, newQuantity: number) => {
    let updatedProducts = additionalProducts.filter((prdt) => prdt.id === id)
    //const filteredProducts = additionalProducts.filter((prdt) => prdt.id !== id)
    if (newQuantity > updatedProducts.length) {
      updatedProducts = [...additionalProducts, updatedProducts[0]]
      setAdditionalProducts(updatedProducts)
    } else {
      const isItem = (el: any) => el.id === id
      const isIndex = additionalProducts.findIndex(isItem)
      const filteredProducts = [...additionalProducts]
      filteredProducts.splice(isIndex, 1)
      setAdditionalProducts(filteredProducts)
    }
  }

  return (
    <>
      <PageNav handleOpenNeedHelp={handleOpenNeedHelp} />
      <Boiler step={checkoutState} totalStep={5} />
      <Container className='pt-5'>
        <div className='d-flex justify-content-between'>
          <h2>
            {checkoutState === 2
              ? 'Additional Options'
              : checkoutState === 3
              ? 'Select your installation Date'
              : checkoutState === 4
              ? 'Your Details'
              : checkoutState === 5
              ? 'Payment'
              : ''}
          </h2>
          <Button variant='outline-dark' onClick={handleBack}>
            Back
          </Button>
        </div>
      </Container>
      <Container className='py-5'>
        <Row>
          <Col md={9}>
            {checkoutState === 2 ? (
              <ProductListing productType='additional' />
            ) : checkoutState === 3 ? (
              <div className='checkout-form'>
                <h3>Please Select</h3>
                <div className='d-flex align-items-center flex-wrap'>
                  <Calendar
                    className={`checkout-calendar ${
                      blockDateLoading ? 'is-loading' : ''
                    }`}
                    formatShortWeekday={formatWeekday}
                    minDate={tomorrow}
                    onChange={(value) => handleInstallationDate(value as Date)}
                    value={installationDate}
                    showNeighboringMonth={false}
                    prev2Label={null}
                    next2Label={null}
                    maxDate={maxDate}
                    onActiveStartDateChange={handleMonthChange}
                    tileDisabled={({ date, view }) =>
                      blockedDates.includes(date.getDate()) ||
                      (view === 'month' && date.getDay() === 0)
                    }
                  />
                  <div className='calendar-info-wrapper text-center p-5'>
                    <BsInfoCircle size='4.5em' color='#fdda00' />
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: '1em',
                        marginTop: '30px',
                      }}
                    >
                      Your engineer will arrive between
                      <br />
                      08:00-09:00
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: '1em',
                        marginTop: '20px',
                      }}
                    >
                      Your installation will take approximately 1 day
                    </p>
                  </div>
                </div>
              </div>
            ) : checkoutState === 4 ? (
              <div className='checkout-form'>
                <Form
                  style={{ maxWidth: 'inherit' }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Form.Group as={Row}>
                    <Form.Label column sm={12} className='checkout-label'>
                      Your Name
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        type='text'
                        className='mt-3'
                        placeholder='First Name'
                        {...register('FirstName', { required: true })}
                      />
                      {errors.FirstName && (
                        <p className='text-danger'>
                          {errors.FirstName.message as string}
                        </p>
                      )}
                    </Col>
                    <Col sm={6}>
                      <Form.Control
                        type='text'
                        className='mt-3'
                        placeholder='Last Name'
                        {...register('LastName', { required: true })}
                      />
                      {errors.LastName && (
                        <p className='text-danger'>
                          {errors.LastName.message as string}
                        </p>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mt-3'>
                    <Form.Label column sm={12} className='checkout-label'>
                      Contact Details
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        type='email'
                        className='mt-3'
                        placeholder='Email'
                        {...register('Email', { required: true })}
                      />
                      {errors.Email && (
                        <p className='text-danger'>
                          {errors.Email.message as string}
                        </p>
                      )}
                    </Col>
                    <Col sm={6}>
                      <Form.Control
                        type='text'
                        className='mt-3'
                        placeholder='Contact Number'
                        {...register('ContactNumber', { required: true })}
                      />
                      {errors.ContactNumber && (
                        <p className='text-danger'>
                          {errors.ContactNumber.message as string}
                        </p>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mt-3'>
                    <Form.Label column sm={12} className='checkout-label'>
                      Installation Address
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type='text'
                        className='mt-3'
                        placeholder='House Name or Number'
                        {...register('HouseNumber', { required: true })}
                      />
                      {errors.HouseNumber && (
                        <p className='text-danger'>
                          {errors.HouseNumber.message as string}
                        </p>
                      )}
                    </Col>
                    <Col sm={12}>
                      <Form.Control
                        type='text'
                        className='mt-3'
                        placeholder='Street Name'
                        {...register('StreetName', { required: true })}
                      />
                      {errors.StreetName && (
                        <p className='text-danger'>
                          {errors.StreetName.message as string}
                        </p>
                      )}
                    </Col>
                    <Col sm={12}>
                      <Form.Control
                        type='text'
                        className='mt-3'
                        placeholder='Town'
                        {...register('Town', { required: true })}
                      />
                      {errors.Town && (
                        <p className='text-danger'>
                          {errors.Town.message as string}
                        </p>
                      )}
                    </Col>
                    <Col sm={6}>
                      <Form.Control
                        type='text'
                        className='mt-3'
                        placeholder='County'
                        {...register('County', { required: true })}
                      />
                      {errors.County && (
                        <p className='text-danger'>
                          {errors.County.message as string}
                        </p>
                      )}
                    </Col>
                    <Col sm={6}>
                      <Form.Control
                        type='text'
                        className='mt-3'
                        placeholder='Postal Code'
                        {...register('PostCode', { required: true })}
                      />
                      {errors.PostCode && (
                        <p className='text-danger'>
                          {errors.PostCode.message as string}
                        </p>
                      )}
                    </Col>
                  </Form.Group>
                  <Button
                    variant='primary'
                    type='button'
                    onClick={handleNextStep}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : 'Payment Method'}
                  </Button>
                  <Form.Control
                    type='hidden'
                    className='mt-3'
                    {...register('InstallationDate', { required: true })}
                  />
                </Form>
              </div>
            ) : checkoutState === 5 && clientSecret.length && orderId ? (
              <Elements stripe={stripe} options={options}>
                {isLoading ? 'loading...' : ''}
                <CheckoutForm orderId={orderId} />
              </Elements>
            ) : (
              <></>
            )}
          </Col>
          <Col md={3}>
            <div>
              {checkoutState === 4 && (
                <div className='order-sidebar mb-3'>
                  <h5>Have a coupon code?</h5>
                  <input
                    type='text'
                    style={{ background: '#fff', height: '44px' }}
                    value={couponName}
                    onChange={handleCouponChange}
                    placeholder='Enter coupon code'
                    className='form-control mb-3 mt-3'
                  />
                  <Button disabled={couponLoading} onClick={handleCheckCoupon}>
                    Add Code
                  </Button>
                  {couponError ? (
                    <p className='text-danger mt-3 mb-0'>Invalid coupon</p>
                  ) : (
                    <></>
                  )}
                  {couponData && (
                    <p className='text-success mt-3 mb-0'>Coupon Applied</p>
                  )}
                </div>
              )}
              <div className='order-sidebar'>
                <h3 className='text-center'>Your Order</h3>
                <div className='mt-4 mb-3'>
                  <p>
                    <strong>Your Boiler</strong>
                  </p>
                  <p className='mb-1'>{product?.data.data?.name}</p>
                  <span>
                    &pound; {additionalPrice + product?.data.data?.price}
                  </span>
                </div>
                <div>
                  <div className='mb-4'>
                    <strong>Your Extras</strong>
                    {/* {additionalProducts.map((prdt) => {
                    return (
                      <div className='mb-3'>
                        <p key={prdt.id} className='mb-1'>
                          {prdt.name}
                        </p>
                        <span>&pound; {prdt.price}</span>
                      </div>
                    )
                  })} */}
                    {groupedProducts.map((prdt: any) => (
                      <div key={prdt.id} className='mb-3'>
                        <p className='mb-1'>{prdt.name}</p>
                        <div className='mb-2'>
                          <span>&pound; {prdt.price}</span>
                          {/* Add quantity selector (+/- box) */}
                          <span> x </span>
                          <input
                            type='number'
                            value={prdt.quantity}
                            min='0'
                            max='100'
                            onChange={(e) =>
                              handleQuantityChange(
                                prdt.id,
                                parseInt(e.target.value)
                              )
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Your Install</strong>
                  </p>
                  <p>
                    {installationDate ? installationDate.toDateString() : ''}
                  </p>
                </div>
                {couponData && (
                  <div>
                    <p>
                      <strong>Coupon discount: </strong> - &pound;
                      {couponData?.data?.data.discountPrice}
                    </p>
                  </div>
                )}
                <hr />
                <div className='d-flex justify-content-between'>
                  <span style={{ fontWeight: 'bold' }}>Total</span>
                  <span style={{ fontWeight: 'bold' }}>
                    &pound;{' '}
                    {(
                      product?.data.data.price +
                      (additionalPrice || 0) -
                      (couponData?.data?.data.discountPrice || 0) +
                      additionalProducts.reduce(
                        (sum, item) => sum + item.price,
                        0
                      )
                    ).toFixed(2)}
                  </span>
                </div>
                <div className='text-center d-flex flex-column gap-2 mt-3'>
                  <strong>Next Step</strong>
                  {checkoutState !== 5 && (
                    <Button
                      disabled={
                        isLoading || (checkoutState > 2 && !installationDate)
                      }
                      onClick={handleNextStep}
                    >
                      {isLoading
                        ? 'Loading'
                        : checkoutState === 2
                        ? 'Select your Install date'
                        : checkoutState === 3
                        ? 'Your Details'
                        : checkoutState === 4
                        ? 'Payment Method'
                        : ''}
                    </Button>
                  )}
                  {errors.InstallationDate && (
                    <p className='text-danger mt-3'>
                      Installation date is required
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Sidebar open={open} needHelp={needHelp} handleClose={handleOpenClose} />
      <Footer />
    </>
  )
}

export default CheckoutPage
