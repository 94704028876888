import { useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { getApiClient } from '../modules/axios'
import { IQuestionsResponse } from '../types/questions'

interface IQuiz {
  question: string
  answer: string
}

interface QuestionBody {
  question_no: number
  ans_option?: string
  quiz: IQuiz[] | []
}

const getQuestions = (data: QuestionBody) => {
  return getApiClient({
    'Content-Type': 'application/json',
  }).post(`/questions/list`, data)
}
export const useGetQuestions = (data: QuestionBody) => {
  return useQuery<AxiosResponse<IQuestionsResponse>, AxiosError>(
    ['getQuestions', data],
    () => getQuestions(data)
  )
}
