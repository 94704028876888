/* eslint-disable @typescript-eslint/ban-types */
import { getApiClient } from '../modules/axios'
import { useGetMutation } from '../modules/mutation'

const checkCoupon = (couponName: string) => {
  return getApiClient({
    'Content-Type': 'application/json',
  }).get(`/coupon/check-coupon`, {
    params: { couponName },
  })
}

export const useCheckCoupon = ({
  onSuccess,
  onError,
}: {
  onSuccess: Function
  onError: Function
}) => {
  return useGetMutation(checkCoupon, onSuccess, onError)
}
