import React, { useContext, useEffect, useState } from 'react'

import Footer from '../components/Footer'
import PageNav from '../components/pageNav'
import QuizSummary from '../components/QuizSummary'
import Sidebar from '../components/Sidebar'
import { CheckoutContext } from '../context/CheckoutContext'

export const QuizSummaryPage = () => {
  const [summary, setSummary] = useState({
    property: '',
    bedroom: '',
    bathtub: '',
    shower: '',
    postcode: '',
    currentBoiler: '',
    newBoiler: '',
  })
  const { quiz } = useContext(CheckoutContext)

  const [open, setOpen] = useState<boolean>(false)
  const [needHelp, setNeedHelp] = useState<boolean>(false)

  const handleOpenClose = () => {
    setOpen(!open)
    if (needHelp) {
      setNeedHelp(!needHelp)
    }
  }

  const handleOpenNeedHelp = () => {
    handleOpenClose()
    setNeedHelp(true)
  }

  useEffect(() => {
    if (quiz.length) {
      const currentBoiler = quiz.find(
        (item) => item.question === 'What type of boiler do you currently have?'
      )
      const newBoiler = quiz.find(
        (item) =>
          item.question ===
          'What type of boiler do you want to replace it with?'
      )
      const postcode = quiz[0]
      const property = quiz.find(
        (item) => item.question === 'What type of property do you have?'
      )
      const bedroom = quiz.find(
        (item) => item.question === 'How many bedrooms do you have?'
      )
      const bathtub = quiz.find(
        (item) => item.question === 'How many bathtubs do you have?'
      )
      const shower = quiz.find(
        (item) => item.question === 'How many separate showers?'
      )
      setSummary({
        property: property?.answer || '',
        bedroom: bedroom?.answer || '',
        bathtub: bathtub?.answer || '',
        shower: shower?.answer || '',
        postcode: postcode.answer || '',
        currentBoiler: currentBoiler?.answer || '',
        newBoiler: newBoiler?.answer || '',
      })
    }
  }, [quiz])
  return (
    <>
      <PageNav handleOpenNeedHelp={handleOpenNeedHelp} />
      <QuizSummary
        property={summary.property}
        bedroom={summary.bedroom}
        bathtub={summary.bathtub}
        shower={summary.shower}
        postcode={summary.postcode}
        newBoiler={summary.newBoiler}
        currentBoiler={summary.currentBoiler}
      />
      <Sidebar open={open} needHelp={needHelp} handleClose={handleOpenClose} />
      <Footer />
    </>
  )
}
