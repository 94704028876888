import { useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { getApiClient } from '../modules/axios'
import { IProductResponse, IProductsResponse } from '../types/products'

const getProduct = (id: string) => {
  return getApiClient().get(`/products/${id}`)
}
export const useGetProduct = (id: string) => {
  return useQuery<AxiosResponse<IProductResponse>, AxiosError>(
    ['getProduct'],
    () => getProduct(id)
  )
}

const getProducts = (productType?: string) => {
  return getApiClient({
    'Content-Type': 'application/json',
  }).get(`/products/list?producttype=${productType || 'main'}&limit=100`)
}
export const useGetProducts = (productType?: string) => {
  return useQuery<AxiosResponse<IProductsResponse>, AxiosError>(
    ['getProducts'],
    () => getProducts(productType),
    {
      staleTime: 0,
    }
  )
}
