import { Button } from 'react-bootstrap'
import { IoInformationCircleOutline } from 'react-icons/io5'
// import { HiArrowLongLeft } from 'react-icons/hi2'
// import { Link } from 'react-router-dom'

// import Footer from '../components/Footer'
// import PageNav from '../components/pageNav'

function HelpPage() {
  return (
    <>
      {/* <PageNav /> */}
      <div
        style={{ height: '80vh' }}
        className='d-flex justify-content-center align-items-center'
      >
        <div>
          <div className='d-flex justify-content-center'>
            <IoInformationCircleOutline
              className='text-yellow'
              style={{ fontSize: '70px', color: '#fdda00' }}
            />
          </div>
          <div className='d-flex flex-column align-items-center'>
            <p className='mt-3 fs-1 text-center' style={{ fontWeight: 'bold' }}>
              Need some help?
            </p>
            <p
              className='fs-6 text-center text-bold'
              style={{ fontWeight: 'bold' }}
            >
              If you have any issues or questions our expert team are on hand on
              help, please give us a call, send an email or request a callback
              and we will do our best to help.
            </p>

            <Button
              className='btn-primary border-0 px-3 py-2 rounded-5 text-bold mt-3'
              href='tel:03303 110 363'
              style={{ fontWeight: 'bold' }}
            >
              Call now
            </Button>
            <p className='mt-3 fs-5' style={{ fontWeight: 'bold' }}>
              03303 110 363
            </p>
            {/* <div className='mt-5'>
              <Link to={'/'}>
                <button
                  className='bg-white border-1 px-3 py-1 rounded-5 text-bold mt-5'
                  style={{ fontWeight: 'bold' }}
                >
                  <HiArrowLongLeft className='fs-4' /> Restart
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default HelpPage
