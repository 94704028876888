import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'

interface Product {
  id: string
  name: string
}

interface AdditionalProduct {
  id: string
  name: string
  price: number
}

interface Quiz {
  question: string
  answer: string
}

interface CheckoutContextType {
  checkoutState: number
  setCheckoutState: Dispatch<SetStateAction<number>>
  products: Product | null
  setProducts: Dispatch<SetStateAction<Product | null>>
  additionalProducts: AdditionalProduct[]
  setAdditionalProducts: Dispatch<SetStateAction<AdditionalProduct[]>>
  installationDate: Date | null
  setInstallationDate: Dispatch<SetStateAction<Date | null>>
  quiz: Quiz[]
  setQuiz: Dispatch<SetStateAction<Quiz[]>>
  prevQues: number[]
  setPrevQues: Dispatch<SetStateAction<number[]>>
  additionalPrice: number
  setAdditionalPrice: Dispatch<SetStateAction<number>>
}

const defaultValue: CheckoutContextType = {
  checkoutState: 1,
  setCheckoutState: () => {
    // do nothing
  },
  products: null,
  setProducts: () => {
    // do nothing
  },
  additionalProducts: [],
  setAdditionalProducts: () => {
    // do nothing
  },
  quiz: [],
  setQuiz: () => {
    // do nothing
  },
  prevQues: [1],
  setPrevQues: () => {
    // do nothing
  },
  additionalPrice: 0,
  setAdditionalPrice: () => {
    // do nothing
  },
  installationDate: null,
  setInstallationDate: () => {
    // do nothing
  },
}

const CheckoutContext = createContext<CheckoutContextType>(defaultValue)

const CheckoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [checkoutState, setCheckoutState] = useState(2)
  const [products, setProducts] = useState<Product | null>({
    id: '123',
    name: 'asasas',
  })
  const [additionalProducts, setAdditionalProducts] = useState<
    AdditionalProduct[]
  >([])
  const [quiz, setQuiz] = useState<Quiz[]>([])
  const [prevQues, setPrevQues] = useState<number[]>([1])
  const [additionalPrice, setAdditionalPrice] = useState<number>(0)
  const [installationDate, setInstallationDate] = useState<Date | null>(null)

  const contextValue: CheckoutContextType = {
    checkoutState,
    setCheckoutState,
    products,
    setProducts,
    additionalProducts,
    setAdditionalProducts,
    quiz,
    setQuiz,
    prevQues,
    setPrevQues,
    additionalPrice,
    setAdditionalPrice,
    installationDate,
    setInstallationDate,
  }

  return (
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  )
}

export { CheckoutContext, CheckoutProvider }
