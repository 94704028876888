import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useState } from 'react'
import Form from 'react-bootstrap/Form'

const CheckoutForm = (props: any) => {
  const stripe = useStripe()
  const elements = useElements()

  const [agreed, setAgreed] = useState(false)

  const handleAgreeChange = () => {
    setAgreed(!agreed)
  }

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `http://api.heatbee.co.uk/checkout/success/${props.orderId}`,
      },
    })

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe || !agreed} className='btn btn-primary mt-3'>
        Submit
      </button>
      <Form.Group controlId='formBasicCheckbox' className='mt-3'>
        <Form.Check
          type='checkbox'
          label='I agree to the terms and conditions'
          checked={agreed}
          onChange={handleAgreeChange}
        />
      </Form.Group>
      <div className='mt-4'>
        <a
          href='https://ideal4finance.com/heatbee?utm_source=3rd&utm_medium=banner&utm_campaign=Banners'
          target='_blank'
          title='Ideal4Finance'
          rel='noreferrer'
        >
          <img
            className='img-fluid'
            src='https://ideal4finance.com/img/web-banners/finance_available/yellow-horizontal.png'
            alt='Ideal4Finance'
          />
        </a>
      </div>
    </form>
  )
}

export default CheckoutForm
