import { HiArrowLongLeft } from 'react-icons/hi2'
import { RxCrossCircled } from 'react-icons/rx'
import { Link } from 'react-router-dom'

interface IProps {
  message: string
}

export default function IssueComponent(props: IProps) {
  return (
    <div style={{ height: '70vh' }}>
      <div className='d-flex justify-content-center mt-5'>
        <RxCrossCircled className='text-yellow' style={{ fontSize: '70px' }} />
      </div>
      <div className='d-flex flex-column align-items-center'>
        <p
          className='mt-3 fs-2 text-center'
          style={{ width: '35vw', fontWeight: 'bold' }}
        >
          {props.message}
        </p>
        <p
          className='fs-5 text-center text-bold'
          style={{ width: '30vw', fontWeight: 'bold' }}
        >
          Sorry for any inconvenience
        </p>
        <Link to={'https://www.heatbee.co.uk'}>
          <button
            className='btn-primary border-0 px-3 py-2 rounded-5 text-bold mt-3'
            style={{ fontWeight: 'bold' }}
          >
            Return to main website
          </button>
        </Link>
        <Link to={'/'}>
          <button
            className='bg-white border-1 px-3 py-1 rounded-5 text-bold mt-3'
            style={{ fontWeight: 'bold' }}
          >
            <HiArrowLongLeft className='fs-4' /> Restart
          </button>
        </Link>
      </div>
    </div>
  )
}
