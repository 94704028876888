import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

interface ISummary {
  property: string
  bedroom: string
  bathtub: string
  shower: string
  postcode: string
  currentBoiler: string
  newBoiler: string
}

const QuizSummary: React.FC<ISummary> = ({
  property,
  bedroom,
  bathtub,
  shower,
  postcode,
  currentBoiler,
  newBoiler,
}) => {
  const navigate = useNavigate()
  return (
    <Container>
      <div
        className='text-center'
        style={{ maxWidth: '500px', margin: '50px auto', fontWeight: 500 }}
      >
        <h2>Your Summary</h2>
        <p className='mt-4'>
          You have told us your property is a {property}, with {bedroom}{' '}
          bedrooms, {bathtub} bathtubs and {shower} seperate shower.
        </p>
        <div className='d-flex justify-content-between mt-5'>
          <span>Postcode</span>
          <span>{postcode}</span>
        </div>
        <hr />
        <div className='d-flex justify-content-between'>
          <span>Current Boiler</span>
          <span>{currentBoiler}</span>
        </div>
        <hr />
        <div className='d-flex justify-content-between'>
          <span>Replacing With</span>
          <span>{newBoiler}</span>
        </div>
        <Button
          className='mt-5'
          onClick={(e) => {
            e.preventDefault()
            navigate('/products')
          }}
        >
          See My Results
        </Button>
        <div>
          <Button href='/' variant='outline-dark' className='mt-5 ml-0'>
            Restart
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default QuizSummary
