import './sidebar.css'

import React from 'react'

import trvImg from '../assets/trv.png'
import HelpPage from '../pages/helpPage'
import Navbar from './Navbar'
import NavContent from './NavContent'

type SidebarProps = {
  handleClose: () => void
  open: boolean
  needHelp: boolean
  product?: any
  isPostcodeInfo?: boolean
  isTRVInfo?: boolean
  isAdditionalInfo?: boolean
}

const Sidebar: React.FunctionComponent<SidebarProps> = ({
  handleClose,
  product,
  open,
  needHelp,
  isPostcodeInfo,
  isTRVInfo,
  isAdditionalInfo,
}) => {
  return (
    <>
      {open && (
        <>
          <div className='sidebarBackdrop'></div>
          <div className={isPostcodeInfo ? 'sidebar bg-white' : 'sidebar'}>
            <Navbar handleClose={handleClose} />
            {needHelp && (
              <div className='nav-content d-flex text-center flex-column w-100'>
                <HelpPage />
              </div>
            )}
            {!isTRVInfo && !isPostcodeInfo && product && !needHelp && (
              <NavContent product={product} />
            )}
            {isPostcodeInfo && !needHelp && (
              <div className='nav-content d-flex text-center flex-column w-100'>
                <div style={{ overflow: 'scroll', fontWeight: 500 }}>
                  <h2
                    className='text-center mb-5'
                    style={{ fontSize: '28px', fontWeight: 700 }}
                  >
                    Areas we cover
                  </h2>

                  <h5 className='mb-5'>
                    We are located in Cheshire cover the following Counties;
                  </h5>
                  <p>Cheshire</p>
                  <p>Merseyside</p>
                  <p>Flintshire</p>
                  <p>Derbyshire</p>
                  <p>Greater Manchester</p>
                  <p>Staffordshire</p>
                  <p>Clwyd</p>
                  <p>Denbighshire</p>
                  <p>Gynedd</p>
                  <p>Powys</p>
                  <p>Shropshire</p>
                  <p>Lancashire</p>
                  <p>West Yorkshire</p>
                  <p>West Midlands</p>
                  <p>Worcestershire</p>
                  <h5 className='mt-5'>
                    To ensure we cover your area please enter just the first
                    part of your postcode to check
                  </h5>
                </div>
              </div>
            )}
            {isTRVInfo && (
              <div className='nav-content d-flex text-center flex-column w-100'>
                <div
                  style={{
                    overflow: 'scroll',
                    fontWeight: 500,
                    maxWidth: '400px',
                    margin: '0 auto',
                  }}
                >
                  <h2
                    className='text-center mb-5'
                    style={{ fontSize: '28px', fontWeight: 700 }}
                  >
                    What is a TRV?
                  </h2>

                  <h5 className='mb-5'>
                    A Thermostatic radiator valve (TRV) is a
                    temperature-controlled valve that adjust hot water flow to
                    regulate radiator heat, enabling energy-efficient and
                    individual room temperature control
                  </h5>
                  <img className='img-fluid' src={trvImg} alt='trv' />
                  <p style={{ fontWeight: 500, margin: '30px 0' }}>
                    Building Regulations for England state that whenever a
                    boiler is replaced, every room needs to have thermostatic
                    controls - which means either a thermostat or a TRV
                  </p>
                </div>
              </div>
            )}
            {isAdditionalInfo && (
              <div className='nav-content d-flex text-center flex-column w-100'>
                <div
                  style={{
                    overflow: 'scroll',
                    fontWeight: 500,
                    maxWidth: '400px',
                    margin: '0 auto',
                  }}
                >
                  <h2
                    className='text-center mb-5'
                    style={{ fontSize: '28px', fontWeight: 700 }}
                  >
                    What's included
                  </h2>
                  <p style={{ fontWeight: 500, margin: '30px 0' }}>
                    All our prices are fixed, and include installation.
                  </p>
                  <p style={{ fontWeight: 500, margin: '30px 0' }}>
                    Removal, disposal and recycling of existing boiler
                    <br />
                    Installation of your new boiler
                    <br /> All required pipework and fixings
                    <br /> Full installation by a Gas Safe engineer
                    <br /> Flue kit, flue extensions and elbows
                    <br /> Wireless thermostat Magnetic boiler filter
                    <br /> Chemical flush on your existing system
                    <br /> Gas soundness and leak test
                    <br /> Re-balance of entire heating system
                    <br />
                    Mains gas pipe upgrade from the metre if required
                    <br /> Repair to any external brickwork
                    <br /> Parts and labour warranty, direct with the
                    manufacturer 12 month workmanship warranty
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Sidebar
